<script setup lang="ts">
useHead({
    bodyAttrs: {
        class: 'bg-gray-50 text-gray-900',
    },
});
const { toggle, isOpen } = useNavigation();
</script>

<template>
    <div>
        <BaseNavMobile />
        <div class="flex min-h-screen">
            <div
                class="fixed left-0 top-0 z-20 h-screen max-h-full bg-gray-300 md:sticky"
                :class="{
                    'hidden drop-shadow md:block': !isOpen,
                }"
            >
                <JamNavInternal class="relative z-10" />
                <div
                    v-if="isOpen"
                    class="fixed left-0 top-0 h-full w-full bg-black/30 2xl:hidden"
                    @click="toggle"
                ></div>
            </div>
            <div class="min-h-screen grow overflow-hidden">
                <header v-if="$slots.header">
                    <slot name="header" />
                </header>
                <main class="h-full max-w-full">
                    <NuxtLoadingIndicator />
                    <slot />
                </main>
            </div>
        </div>
        <JamBaseNotificationWrapper />
    </div>
</template>
