<script setup lang="ts">
import { useRouter } from 'vue-router';

import { useNotification } from '../composables/useNotification';
import type { JamForm } from '../types/jamForm';
import type { JamBaseSelectOption } from './JamBaseSelect.vue';

type NavItem = {
    id: String;
    title: String;
    to: String;
    active?: Boolean;
    method?: string;
    as?: string;
    click?: Function;
    elementDetail?: String;
};

type ApiResponseItem = {
    type: string;
    id: string;
    attributes: {
        origin: string;
        label: string;
        details: string | null;
    };
    links: {
        self: string;
    };
};

const { user: userInfo, abilities } = useUser();

const isLocked = useMatchMedia('(max-width: 767px)');
const isFHD = useMatchMedia('(min-width: 1920px');
const { isOpen, open, close } = useNavigation();
const isExtended = computed(() => isLocked.value || isOpen.value);
const variant = computed(() => (isExtended.value ? 'extended' : 'collapsed'));
const router = useRouter();
const { error } = useNotification();

const searchTerm = ref('');

const onSearch = async (searchTerm: string) => {
    if (searchTerm.startsWith('j:')) {
        const jobId = searchTerm.split(':')[1];
        try {
            const response = await $fetch<{
                data: JamForm;
                status: 'success' | 'error';
            }>(`/api/manage-job-ads/internal/job-ads/${jobId}`);
            await router.push(
                `/p/internal/companies/${response.data.meta?.companyId}?filter[searchTerm]=j:${jobId}`,
            );
            close();
        } catch (e) {
            error('Job nicht gefunden');
        }
    }
};

const handleOptionSelect = async (selectedOption: JamBaseSelectOption) => {
    await router.push(`/p/internal/companies/${selectedOption.value}`);
    close();
};

const comboBoxOptionsCallback = async (term: string) => {
    const response = await fetch(
        `/api/manage-job-ads/internal/companies/autocomplete?input=${term}`,
    );
    const data: ApiResponseItem[] = await response.json();

    // Transforming data and type-casting to `Suggestion[]`
    return data.map(
        (item: ApiResponseItem): JamBaseSelectOption => ({
            label: item.attributes.label,
            value: item.id,
        }),
    );
};

const profileNavItems: NavItem[] = [];
profileNavItems.push({
    as: 'button',
    elementDetail: 'Logout',
    id: '1',
    method: 'post',
    title: 'Abmelden',
    to: '/api/auth/internal-identity-provider/logout',
});

onMounted(() => {
    if (isFHD.value) {
        open();
    }
});
</script>

<template>
    <TheNavShell>
        <div class="grow">
            <!-- User details -->
            <div class="py-6 text-center">
                <!-- Profile image -->
                <img
                    :src="
                        userInfo?.avatar ?? `/p/icons/profile-placeholder.svg`
                    "
                    alt="Profilbild"
                    class="mx-auto h-[88px] w-[88px] rounded-full object-cover transition-all"
                    :class="[!isExtended && '!h-[40px] !w-[40px]']"
                />
                <div
                    v-if="isExtended"
                    class="mt-3 min-w-[calc(320px-2*var(--space-5))]"
                >
                    <JamBaseText variant="base" class="text-gray-900">
                        {{ userInfo?.name }}
                    </JamBaseText>
                </div>
            </div>

            <!-- Navigation -->
            <div class="mb-5 flex flex-col gap-3 last:mb-0">
                <TheNavCollapse
                    title="Profil"
                    icon-name="profile"
                    :children="profileNavItems"
                    :variant="variant"
                />
                <TheNavLink
                    v-if="abilities.qaPages"
                    title="QA"
                    icon-name="job-ad"
                    to="/p/internal/qa"
                    :variant="variant"
                    :active="false"
                />
            </div>

            <JamBaseComboBox
                v-if="abilities.adsList && isExtended"
                v-model="searchTerm"
                icon="search"
                placeholder="Suche nach Kunde oder Job"
                :enter-callback="onSearch"
                :options-callback="comboBoxOptionsCallback"
                :on-select-option="handleOptionSelect"
                ignore-enter-on-prefix="j:"
            />
        </div>
    </TheNavShell>
</template>
